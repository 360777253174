import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useMediaQuery,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { ChevronLeft, Help, Person } from "@material-ui/icons";
import * as S from "./styles";
import { Timer } from "material-ui-icons";
import { trackMetric } from "../../../actions/plataformActions";

export const MiniCursoAppBar = ({ title, subtitle, onClose }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  const concursoName = useSelector((state) => state.concurso.active.name);

  console.log(isMobile);

  const handleOpenTimer = useCallback(() => {
    const token = localStorage.token.replace("Bearer ", "");

    dispatch(
      trackMetric("Clicou no cronômetro", {
        url: location.href,
      })
    );

    window.open(
      `https://clippingcacd.com.br/timer/${concursoName}?token=${token}`,
      "_blank"
    );
  }, []);

  return (
    <S.MiniCursoAppBar position="static">
      <Button
        style={{
          minWidth: "fit-content",
        }}
        onClick={() => {
          push("/mini-cursos");
          onClose();
        }}
      >
        <ChevronLeft />
        {!isMobile && <Typography>Voltar</Typography>}
      </Button>
      <S.MiniCursoAppBarTitle>
        <Typography color="textPrimary" textAlign="center">
          {title}
        </Typography>
        <Typography color="textPrimary" textAlign="center">
          {subtitle}
        </Typography>
      </S.MiniCursoAppBarTitle>
      <S.MiniCursoMenu>
        <Tooltip title="Ajuda">
          <a href="https://ajuda.clippingcacd.com.br/pt-BR/" target="_blank">
            <IconButton style={{ borderRadius: "4px !important" }}>
              <Help />
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Cronômetro de estudos">
          <IconButton onClick={() => handleOpenTimer()}>
            <Timer />
          </IconButton>
        </Tooltip>
        {!isMobile && (
          <Tooltip title="Ajustes do perfil">
            <IconButton onClick={() => push("/perfil")}>
              <Person />
            </IconButton>
          </Tooltip>
        )}
      </S.MiniCursoMenu>
    </S.MiniCursoAppBar>
  );
};
