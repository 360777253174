import api from "./api";

export function createBiblioSubjectMaterialUser({
  concursoId,
  material,
  subjectId,
  resourceId,
  resourceName,
  week = 0,
}) {
  return api.post("/biblioSubjectMaterialUser", {
    subject: subjectId,
    material,
    concursoId,
    resourceId,
    resourceName,
    week,
  });
}

export function deleteBiblioSubjectMaterialUser({
  concursoId,
  material,
  resourceId,
  resourceName,
}) {
  return api.delete("/biblioSubjectMaterialUser/byresourceid", {
    data: {
      material,
      resourceId,
      resourceName,
      concursoId,
    },
  });
}

export function getBiblioSubjectMaterialUser(subjectId, concursoId) {
  if (!subjectId || !concursoId) {
    return;
  }

  return api.get(`/biblio-user-data`, {
    params: {
      subjectId,
      concursoId,
    },
  });
}
