import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Dialog, DialogTitle } from "@material-ui/core";
import { MiniCursoSidebar } from "./MiniCursoSidebar";
import { MiniCursoAppBar } from "./MiniCursoAppBar";
import { MiniCursoContent } from "./MiniCursoContent";
import { getBiblioSubjectMaterialUser } from "../../../services/biblio-subject-material-user";
import * as S from "./styles";

export function MiniCurso({
  isOpen = false,
  miniCourseTitle,
  modules,
  moduleId,
  selecteModule,
  onClose,
}) {
  const concursoId = useSelector((state) => state.concurso.active.concursoId);
  const selectedModule = modules.find((module) => module._id === moduleId);
  const subjectId = selectedModule?.subject;

  const {
    data: biblioUserData,
    isLoading,
    isError,
    refetch: refetchBiblioUserData,
  } = useQuery({
    queryKey: ["biblioUserData", isOpen, concursoId, subjectId],
    queryFn: () => getBiblioSubjectMaterialUser(subjectId, concursoId),
    enabled: isOpen && Boolean(subjectId),
  });

  if (isLoading && !isError) {
    return (
      <Dialog open={isOpen} onClose={onClose} fullScreen>
        <DialogTitle>{miniCourseTitle}</DialogTitle>

        <S.MiniCursoGridContainer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress />
        </S.MiniCursoGridContainer>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogTitle>{miniCourseTitle}</DialogTitle>
      <MiniCursoAppBar
        title={miniCourseTitle}
        subtitle={selectedModule?.title ?? ""}
        onClose={onClose}
      />
      <S.MiniCursoGridContainer container>
        <MiniCursoSidebar
          modules={modules}
          moduleId={moduleId}
          selecteModule={selecteModule}
        />
        <MiniCursoContent
          key={moduleId}
          concursoId={concursoId}
          moduleId={moduleId}
          subjectId={subjectId}
          miniCourseId={selectedModule?._id}
          goal={selectedModule?.goal}
          apostilas={selectedModule?.apostilas}
          videos={selectedModule?.videos}
          biblioUserData={biblioUserData.data ?? []}
          refetchBiblioUserData={refetchBiblioUserData}
        />
      </S.MiniCursoGridContainer>
    </Dialog>
  );
}
