import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styled from "styled-components";

const CustomDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4px;

  @media (min-width: 1200px) {
    padding: 16px;
    width: 800px;
    height: 450px;
  }
`;

const YoutubeIFrame = ({ video }) => {
  return (
    <iframe
      id={`youtube-${video._id}`}
      name={`youtube-${video._id}`}
      src={`https://www.youtube.com/embed/${video.youtube}`}
      width="100%"
      height="100%"
      allowFullScreen
    ></iframe>
  );
};

const VimeoIFrame = ({ video }) => {
  return (
    <iframe
      id={`vimeo-${video._id}`}
      name={`vimeo-${video._id}`}
      src={`https://player.vimeo.com/video/${video.vimeo}`}
      width="100%"
      height="100%"
      allowFullScreen
    ></iframe>
  );
};

const VideoIFrame = ({ video }) => {
  if (video?.youtube) {
    return <YoutubeIFrame video={video} />;
  }

  if (video?.vimeo) {
    return <VimeoIFrame video={video} />;
  }

  return <></>;
};

export function MiniCursoVideo({ isOpen, onClose, video }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (!isOpen || !video) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth={isMobile}>
      <DialogTitle>{video.name}</DialogTitle>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 16,
        }}
      >
        <Typography variant="h6">{video.name}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <CustomDialogContent>
        <VideoIFrame video={video} />
      </CustomDialogContent>
    </Dialog>
  );
}
