import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withStyles } from "material-ui/styles";
import { ElementList, Title } from "../../components/common";
import Grid from "material-ui/Grid";
import { FetchList } from "../../components";
import CardItem from "./CardItem";
import {
  crudGetList as crudGetListAction,
  crudCreate as crudCreateAction,
  crudDelete as crudDeleteAction,
} from "../../actions/dataActions";
import ModalClipping from "../Clippings/ModalClipping";
import { push } from "react-router-redux";
const bookmarkResource = "bookmark/byresourcetype/article";
import { SORT_ASC } from "../../reducers/resource/list/queryReducer";
import { trackMetric as trackMetricAction } from "../../actions/plataformActions";

const resource = "extraSection";
const fixedFilters = { sort: { field: "order", order: SORT_ASC } };

const styles = (theme) => ({
  content: {
    paddingTop: "8px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "32px",
    },
  },
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
  },
  contentMain: {
    padding: "40px 16px 16px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  contentSidebar: {
    display: "none",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      padding: "40px 0 0 0",
      marginTop: "150px",
      marginLeft: "0",
      marginRight: "auto",
      display: "block",
      order: "1",
    },
  },
  root: {
    flexGrow: 1,
    padding: "8px",
  },
  tabsContainer: {
    height: "40px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class Extras extends Component {
  constructor(props) {
    super(props);

    const { clippingId, articleId } = props.routeParams;

    this.state = {
      open: false,
      modal: clippingId ? { clippingId, articleId } : false,
    };
  }

  handleOpenClose = (_id) => {
    const { trackMetric } = this.props;
    Boolean(_id) && trackMetric("Extras - Intenção de uso", { target: "card", trigger: "click", resource: _id });
    this.state.open === _id ? this.setState({ open: false }) : this.setState({ open: _id });
  };

  handleModal = (clipping) => {
    this.setState({ modal: clipping });
    this.props.pushRouter(`/extras${clipping ? "/resumo/" + clipping.clippingId + "/" + clipping.articleId : ""}`);
  };

  componentWillReceiveProps(nextProps) {
    const { clippingId, articleId } = nextProps.routeParams;
    if (this.props.routeParams.clippingId !== clippingId || this.props.routeParams.articleId !== articleId) {
      this.setState({ modal: clippingId ? { clippingId, articleId } : false });
    }
  }

  handleBookmark = (data, marked) => {
    const { setBookmark, deleteBookmark } = this.props;
    if (marked) {
      setBookmark(data);
    } else {
      deleteBookmark(data);
    }
  };

  render() {
    const { classes, bookmarks = {} } = this.props;
    const { open, modal } = this.state;

    return (
      <div>
        <section className={classes.content}>
          <Grid container className={classes.root}>
            <Grid item xs={12} md={12} className={classes.contentMain}>
              <FetchList {...this.props} fixedFilters={fixedFilters} feedback={true} resource={resource}>
                <ElementList
                  renderRow={(props) =>
                    props.title !== "Resumos" &&
                    props.title !== "Provas Anteriores" &&
                    props.title !== "Provas discursivas anteriores" && (
                      <CardItem {...props} handleOpenClose={this.handleOpenClose} active={open === props._id} />
                    )
                  }
                />
              </FetchList>
            </Grid>
          </Grid>
        </section>
        <ModalClipping
          handleOpen={this.handleModal}
          open={modal}
          handleRoteiro={this.handleRoteiro}
          bookmarks={bookmarks}
          handleBookmark={this.handleBookmark}
        />
      </div>
    );
  }
}

Extras.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  getBookmarks: () => crudGetListAction(bookmarkResource, { page: 1, perPage: 9999 }, { field: "createdAt", order: "ASC" }, {}, false),
  setBookmark: (data) => crudCreateAction(bookmarkResource, data, "", false, "", false, "Artigo salvo em Ler Depois"),
  deleteBookmark: (data = {}) =>
    crudDeleteAction(bookmarkResource, data.resourceId, "", false, false, false, "Artigo removido de Ler Depois"),
  trackMetric: (name, data) => trackMetricAction(name, data),
};

const mapStateToProps = (state, ownProps) => {
  return {
    bookmarks: state[bookmarkResource].data,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Extras);
