// @flow

import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { withStyles } from "material-ui/styles";
import { ListItem } from "material-ui/List";
import api from "../../services/api";

import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { activeRoute } from "../../utils";
import { trackMetric } from "../../actions/plataformActions";
import { useDispatch, useSelector } from "react-redux";

const styles = (theme) => ({
  button: theme.mixins.gutters({
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "none",
    width: "252px",
    height: "40px",
    borderRadius: "40px",
    padding: "10px 16px !important",
    marginTop: "12px",

    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),

    "&:first-child": {
      marginTop: "0px",
    },

    "&:hover": {
      background: "#EAE3E1",

      "@media screen and (max-width: 1279px)": {
        background: "#F2EEED",
      },

      "& svg": {
        fill: "#FC5836",
      },
    },
  }),

  title: {
    flex: 1,
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: '"Inter" !important',
    color: "rgba(0, 0, 0, 0.6)",
    marginLeft: "16px",
  },
  titleActive: {
    color: "#FC5836 !important",
  },
  nestedList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 16px",

    transition: "max-height ease 200ms, padding ease 200ms",
  },
  visible: {
    maxHeight: "500px",
  },
  hidden: {
    maxHeight: "0px",
    padding: "0px 16px !important",
    overflow: "hidden",
  },
  nestedListItem: {
    cursor: "pointer",
    marginBottom: "16px",
    marginLeft: "20px",

    "&:last-child": {
      marginBottom: "0px",
    },

    "&:hover": {
      "& span": {
        color: "#FC5836",
      },
    },
  },
  navItem: {
    ...theme.typography.body2,
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLinkButton: {
    color: theme.palette.black.a6,
    textIndent: theme.spacing.unit * 3,
    fontSize: 15,
    paddingRight: "0px",
    paddingLeft: "0px",
    textAlign: "left",
    fontFamily: "Inter",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      fill: "rgba(0,0,0, 0.4)",
      width: "20px",
      height: "20px",
    },
  },
  expandIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    transition: "transform ease 200ms",

    "& svg": {
      fill: "rgba(0,0,0, 0.4) !important",
      width: "20px",
      height: "20px",
    },
  },
  activeButton: {
    "@media screen and (max-width: 1279px)": {
      background: "#F2EEED",
    },

    background: "#EAE3E1",

    "& svg": {
      fill: "#FC5836",
    },
  },
  soon: {
    position: "absolute",
    bottom: "0px",
    left: "50px",
    height: "14px",
    width: "55px",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "2px",
    lineHeight: "13px",
    fontSize: "11px",
    textIndent: "0px",
    textAlign: "center",
  },

  roundAlert: {
    backgroundColor: "#fe3f4b",
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    position: "relative",
    marginLeft: "8px",
  },
});

function isActive(href = "", location, items = []) {
  if (items.length) {
    return Boolean(items.find((item) => activeRoute(item.href, location)));
  }

  return activeRoute(href, location);
}

function AppDrawerNavItem({
  classes,
  href,
  title,
  icon,
  pushRouter,
  closeDrawer,
  location,
  soon = false,
  expandable,
  items = [],
  expandedItem,
  setExpandedItem,
}) {
  const dispatch = useDispatch();
  const concurso = useSelector((state) => state.concurso.active.name);

  const active = isActive(href, location, items);

  const [expanded, setExpanded] = useState(active);

  function extractFeature(title) {
    switch (title) {
      case "Plano de Estudos":
        return "Bibliografia";
      case "Edital Esquematizado":
        return "Edital";
      case "Meus Fichamentos":
        return "Cadernos";
      default:
        return title;
    }
  }

  function handleClick(_href, nestedItem) {
    setExpandedItem(title);

    if (expandable && !nestedItem) {
      return setExpanded(!expanded);
    }

    dispatch(
      trackMetric(`${extractFeature(title)} - Intenção de uso`, {
        target: "menu",
        trigger: "click",
      })
    );

    if (_href.startsWith("http")) {
      if (_href.includes("kderno.com/")) {
        try {
          var windowReference = window.open("", "_blank");
          api.defaults.headers.Authorization = localStorage.getItem("token");
          api.get(`/kderno-one-time-token`).then(({ data }) => {
            windowReference.location = `${_href}?onetime=${data.data.token}`;
          });
        } catch (e) {
          window.open(_href);
        }
      } else {
        window.open(_href, "_blank");
      }
    } else {
      pushRouter(_href);
      closeDrawer();
    }
  }

  useEffect(() => {
    setExpanded(active);

    if (active && items.length > 0) setExpandedItem(title);
  }, [concurso]);

  useEffect(() => {
    if (expandedItem && title !== expandedItem) setExpanded(false);
  }, [expandedItem]);

  return (
    <React.Fragment>
      <ListItem
        className={classNames(
          classes.button,
          active ? classes.activeButton : ""
        )}
        disableGutters
        onClick={() => (soon ? false : handleClick(href, false))}
      >
        <div className={classes.icon}>{icon}</div>
        <span className={classes.title}>{title}</span>
        {expandable && (
          <div
            className={classes.expandIcon}
            style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
          >
            {<ExpandMore />}
          </div>
        )}
      </ListItem>
      {expandable && (
        <div
          className={classNames(
            classes.nestedList,
            expanded ? classes.visible : classes.hidden
          )}
        >
          {items.map(
            (item, index) =>
              item && (
                <div
                  key={index}
                  onClick={() => handleClick(item.href, true)}
                  className={classes.nestedListItem}
                >
                  <span
                    className={classNames(
                      classes.title,
                      activeRoute(item.href, location)
                        ? classes.titleActive
                        : ""
                    )}
                  >
                    {item.title}
                  </span>
                </div>
              )
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default withStyles(styles)(AppDrawerNavItem);
