import styled from "styled-components";

export const MiniCoursesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  font-family: "Proxima Nova", sans-serif;
  gap: 8px;

  @media (max-width: 1200px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
`;

export const MiniCourseCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 64px;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    box-shadow: 0 22px 10px 0 rgba(0, 0, 0, 0.2);
  }

  &[data-selected="true"] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &[data-selected="true"]:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const MiniCourseCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MiniCourseCardTitle = styled.span`
  font-size: 24px;
  font-weight: 300;
  color: #333;
`;

export const MiniCourseList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const MiniCourseModuleTitle = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: #333;
`;

export const MiniCourseModule = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
