import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Router, IndexRedirect } from "react-router";
// import { ApmRoute } from "@elastic/apm-rum-react";

import { useDispatch } from "react-redux";
import { classSelectorModalAction } from "./containers/Cursos/actions";
import {
  setCreditPayModalOpen,
  setPaymentModalOpen,
  setPaymentStatusModalOpen,
} from "./containers/Payments/actions";

import AppWrapper from "./containers/AppWrapper";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Mural from "./containers/Mural";
import ListaCursos from "./containers/ListaCursos";
import Clippings from "./containers/Clippings";
import Bibliografia from "./containers/Bibliografia";
import Cadernos from "./containers/Cadernos";
import Discursos from "./containers/Discursos";
import Extras from "./containers/Extras";
import Resumos from "./containers/Resumos";
import ProvasAnteriores from "./containers/ProvasAnteriores";

import Profile from "./containers/Profile";
import Assinatura from "./containers/Assinatura";
import ListaLeitura from "./containers/ListaLeitura";

import EditalEsquematizado from "./containers/EditalEsquematizado";
import Cursos from "./containers/Cursos";
import Curso from "./containers/Curso";
import CursosIntensivo from "./containers/CursosIntensivo";
import Busca from "./containers/Busca";
import Temas from "./containers/Temas";
import LeiSeca from "./containers/LeiSeca";

import CheckoutSelect from "./containers/Checkout";
import Checkout from "./containers/Checkout/Checkout";

import Simulados from "./containers/Simulados";
import Questoes from "./containers/Questions";
// import Questoes from './containers/Simulados/QuestoesDrawer';
import Redirect from "./containers/Checkout/Redirect";
import Check from "./containers/CheckAPI";
import MiniCursos from "./containers/MiniCursos";

function Routes(props) {
  const dispatch = useDispatch();
  const handleRoute = (e) => {
    if (e.location.pathname !== "/cursos") {
      // dispatch(courseModalAction({ open: false }));
      dispatch(classSelectorModalAction({ open: false }));
      dispatch(setPaymentModalOpen(false));
      dispatch(setCreditPayModalOpen(false));
      dispatch(setPaymentStatusModalOpen(false));
    }
    window.Intercom && window.Intercom("update");
  };
  return (
    <BrowserRouter>
      <Router {...props}>
        <Route path="login" component={Login}></Route>
        <Route path="/" component={AppWrapper} onChange={handleRoute}>
          <IndexRedirect to="mural" />
          <Route path="mural" component={Mural} name="mural" label="Mural" />
          <Route path="check" component={Check} name="check" label="Check" />
          <Route
            path="mural/:_id"
            component={Mural}
            name="Post"
            parentUrl="/mural"
            label="Post"
          ></Route>
          <Route
            path="lista-cursos"
            component={ListaCursos}
            name="area-cursos"
            label="Area de Cursos"
          />
          <Route
            path="lista-cursos/:_id"
            component={ListaCursos}
            name="Post"
            parentUrl="/mural-cursos"
            label="Post"
          ></Route>
          <Route
            path="busca/:resource"
            component={Busca}
            name="busca"
            label="Busca"
          ></Route>
          <Route
            path="temas/:_id"
            component={Temas}
            name="temas"
            label="Temas"
          ></Route>
          <Route
            path="clippings"
            component={Clippings}
            name="noticias-diarias"
            label="Notícias Diárias"
            resource="clipping"
          ></Route>
          <Route
            path="clippings/:clippingId"
            component={Clippings}
            name="artigos"
            parentUrl="/clippings"
            parentLabel="Notícias Diárias"
            label="Artigos"
          ></Route>
          <Route
            path="clippings/:clippingId/:articleId"
            component={Clippings}
            name="artigos"
            parentUrl="/clippings"
            parentLabel="Clippings"
            label="Artigos"
          ></Route>
          <Route
            path="plano-de-estudos"
            component={Bibliografia}
            name="organizador"
            label="Bibliografia"
          />
          <Route
            path="plano-de-estudos/:_id(/:exhibition)"
            component={Bibliografia}
            name="organizador"
            label="Bibliografia"
          ></Route>
          <Route
            path="plano-de-estudos/:_id/gabarito/:gabaritoId"
            component={Bibliografia}
            name="organizador"
            label="Bibliografia"
          ></Route>
          <Route
            path="plano-de-estudos/:_id/caderno/:cadernoId"
            component={Bibliografia}
            name="fichamentos"
            parentUrl="/plano-de-estudos/:_id"
            parentLabel="Bibliografia"
            label="Fichamentos"
          ></Route>
          <Route
            path="plano-de-estudos/:_id(/:exhibition)/:questaoId"
            component={Bibliografia}
            name="questao"
            label="Bibliografia"
          ></Route>
          <Route
            path="mini-cursos"
            component={MiniCursos}
            name="mini-cursos"
            label="Mini-Cursos"
          ></Route>
          <Route
            path="mini-cursos/:miniCourseId/:moduleId"
            component={MiniCursos}
            name="mini-cursos"
            label="Mini-Cursos"
          ></Route>
          <Route
            path="cadernos"
            component={Cadernos}
            name="meus-fichamentos"
            label="Meus Fichamentos"
            resource="notebook"
          ></Route>
          <Route
            path="cadernos/:_id"
            component={Cadernos}
            name="fichamentos"
            parentUrl="/cadernos"
            parentLabel="Meus Fichamentos"
            label="Fichamentos"
          ></Route>
          <Route
            path="cadernos/:_id/:fichamentoId"
            component={Cadernos}
            name="fichamentos"
            parentUrl="/cadernos"
            parentLabel="Fichamentos"
            label="Fichamentos"
          ></Route>
          <Route
            path="ler-depois"
            component={ListaLeitura}
            name="ler-depois"
            label="Ler Depois"
            resource="bookmark"
          ></Route>
          <Route
            path="ler-depois/:_id"
            component={ListaLeitura}
            name="ler-depois"
            label="Ler Depois"
          ></Route>
          <Route
            path="lei-seca"
            component={LeiSeca}
            name="lei-seca"
            label="Lei Seca"
          ></Route>
          <Route
            path="edital-esquematizado"
            component={EditalEsquematizado}
            name="edital-esquematizado"
            label="Edital Esquematizado"
          ></Route>
          <Route
            path="edital-esquematizado/:_id"
            component={EditalEsquematizado}
            name="edital-esquematizado"
            label="Edital Esquematizado"
          ></Route>
          <Route
            path="simulados"
            component={Simulados}
            name="simulados"
            label="Simulados e Questoes"
            resource="test"
          ></Route>
          <Route
            path="questions"
            component={Questoes}
            name="questions"
            label="Simulados e Questoes"
            resource="questions"
          ></Route>
          <Route
            path="simulados/:_id"
            component={Simulados}
            name="simulados"
            label="Simulados e Questoes"
          ></Route>
          <Route
            path="simulados/:_id/:type"
            component={Simulados}
            name="simulados"
            label="Simulados e Questoes"
          ></Route>
          <Route
            path="discursos-oficiais"
            component={Discursos}
            name="discursos-oficiais"
            label="Discursos Oficiais"
            resource="doeSection"
          ></Route>
          <Route
            path="resumos"
            component={Resumos}
            name="resumos"
            label="Resumos"
          ></Route>
          <Route
            path="provas-anteriores"
            component={ProvasAnteriores}
            name="provas-anteriores"
            label="Provas Anteriores"
          />
          <Route
            path="extras"
            component={Extras}
            name="extras"
            label="Ferramentas"
          ></Route>
          <Route
            path="resumos/resumo/:clippingId/:articleId"
            component={Resumos}
            name="resumos"
            parentUrl="/resumos"
            parentLabel="Resumos"
            label="Resumos"
          ></Route>
          <Route
            path="extras/resumo/:clippingId/:articleId"
            component={Extras}
            name="resumos"
            parentUrl="/extras"
            parentLabel="Ferramentas"
            label="Resumos"
          ></Route>

          <Route
            path="cursos"
            component={Cursos}
            name="cursos"
            label="Cursos"
          ></Route>
          <Route
            path="cursos/:_courseID/modulo/:_moduleID/aula/:_classID"
            component={Curso}
            name="Curso"
            label="Curso"
          ></Route>
          <Route
            path="cursos/:_courseID/presentation/"
            component={Curso}
            name="Curso"
            label="Curso"
          ></Route>
          <Route
            path="cursos-segunda-fase/:_id"
            component={CursosIntensivo}
            name="cursos"
            label="Cursos"
          ></Route>
          <Route
            path="pacotes-cursos"
            component={Redirect}
            name="Pacote de Cursos"
            label="Matrículas"
          />
          <Route
            path="pacotes-cursos-old"
            component={Redirect}
            name="Pacote de Cursos"
            label="Matrículas"
          />
          <Route
            path="pacotes-aprofundamento"
            component={Redirect}
            name="Pacote de Cursos"
            label="Matrículas"
          />
          <Route
            path="pacotes-aprofundamento-mod4"
            component={Redirect}
            name="Pacote de Cursos"
            label="Matrículas"
          />
          <Route
            path="pacotes-intensivo"
            component={Redirect}
            name="Pacote de Cursos"
            label="Matrículas"
          />
          <Route
            path="checkout"
            component={CheckoutSelect}
            name="Tipos de pacotes"
            label="Pacotes"
          />
          <Route
            path="pacotes-dce"
            component={Redirect}
            name="Tipos de pacotes"
            label="Pacotes"
          />
          <Route
            path="checkout/:url"
            component={Checkout}
            name="Pacotes"
            label="Pacotes"
          />
          <Route
            path="concurso/:concurso(/plano/:_id)"
            component={Assinatura}
            name="assinatura"
            label="Assinatura"
          ></Route>
          <Route
            path="concurso/:concurso/plano/:_id/confirmacao"
            component={Assinatura}
            name="assinatura"
            label="Assinatura"
          ></Route>
          <Route
            path="concurso/:concurso/plano/:_id/confirmacao/:tela"
            component={Assinatura}
            name="assinatura"
            label="Assinatura"
          ></Route>
          <Route
            path="perfil"
            component={Profile}
            name="perfil"
            label="Perfil"
          ></Route>
          <Route
            path="perfil/:section"
            component={Profile}
            name="perfil"
            label="Perfil"
          ></Route>
          <Route
            path="cursos/:_id/pagar_aula/:courseClass/:value"
            component={Cursos}
            name="cursos"
            label="Cursos"
          ></Route>
          <Route path="/*" notFound component={NotFound} />
        </Route>
      </Router>
    </BrowserRouter>
  );
}
export default Routes;
