import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isAfter } from "date-fns";
// import Helmet from 'react-helmet';
import DivulgationBar from "../../components/DivulgationBar";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Snackbar from "material-ui/Snackbar";
import Alert from "../../components/Alert";
import JssProvider from "react-jss/lib/JssProvider";
import getContext from "../../styles/getContext";
import AppFrame from "./AppFrame";
import { titleFor } from "../../utils/strings";
import { userFetchRequest as userFetchRequestAction } from "../User/actions";
import { userMount as userMountAction } from "../../actions/authActions";

import createGenerateClassName from "material-ui/styles/createGenerateClassName";
const generateClassNameDev = createGenerateClassName();

import { generateClassName } from "./renameClasses";
import SocketClient from "../../services/paymentSocket";
import ResizeListener from "./ResizeListener";
import { ThemeProvider } from "styled-components";
import { light } from "@clippingcacd/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

class AppWrapper extends PureComponent {
  state = {
    online: true,
    showSnackbar: false,
    showBar: false,
  };

  // verify Date() {
  //   const currentDate = new Date();
  //   const eventDate = new Date(2020, 5, 24, 23, 59, 59, 0);
  //   if (isAfter(currentDate, eventDate)) {
  //     this.setState(...this.state, { showBar: false });
  //   } else {
  //     this.setState(...this.state, { showBar: localStorage.getItem("showDivulgation") === null ? false : true });
  //   }
  //   return;
  // }

  componentWillMount() {
    this.styleContext = getContext();
    this.props.userFetchRequest();

    // Remove the server-side injected CSS.
    // const jssStyles = document.querySelector('#jss-server-side');
    // if (jssStyles && jssStyles.parentNode) {
    //   jssStyles.parentNode.removeChild(jssStyles);
    // }
  }

  componentDidMount() {
    this.props.userMount();

    // this.verifyDate();

    // this.interval = setInterval(() => {
    //   this.verifyDate();
    // }, 15000);

    //commented line
    // this.setState(...this.state, { showBar: localStorage.getItem("showDivulgation") === null ? false : true });
    // this.setState(...this.state, { showBar: false });
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  handleRequestClose = () => {
    this.setState({
      showSnackbar: false,
    });
  };

  renderOffOnlineSnackbar() {
    const { online, showSnackbar } = this.state;
    const msg = !online ? "Offline" : "Online";
    return (
      <Snackbar
        open={showSnackbar}
        resumeHideDuration={2000}
        message={msg}
        autoHideDuration={2000}
        onClose={this.handleRequestClose}
        onRequestClose={this.handleRequestClose}
      />
    );
  }

  hideBar = () => {
    localStorage.removeItem("showDivulgation");
    this.setState({
      showBar: false,
    });
  };

  render() {
    const { location, children, concurso } = this.props;
    const { showBar } = this.state;
    let { label, notFound } = children.props.route;

    const queryClient = new QueryClient();

    if (label === "Bibliografia") {
      label = titleFor("bibliografia", concurso);
    }

    return (
      <JssProvider
        jss={this.styleContext.jss}
        generateClassName={
          process.env.NODE_ENV === "production"
            ? generateClassName
            : generateClassNameDev
        }
      >
        <ThemeProvider theme={light}>
          <MuiThemeProvider
            theme={this.styleContext.theme}
            sheetsManager={this.styleContext.sheetsManager}
          >
            <QueryClientProvider client={queryClient}>
              <div>
                {showBar && <DivulgationBar hideBar={this.hideBar} />}
                <Alert />
                {/* <Helmet meta={metas} link={links} /> */}
                <AppFrame
                  divulgation={showBar}
                  location={location}
                  routeLabel={label}
                  notFound={notFound}
                >
                  {children}
                </AppFrame>
                {this.renderOffOnlineSnackbar()}
              </div>
            </QueryClientProvider>
            <ResizeListener />
            <SocketClient />
          </MuiThemeProvider>
        </ThemeProvider>
      </JssProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    concurso: state.concurso.active,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userFetchRequest: (path) => dispatch(userFetchRequestAction(path)),
    userMount: () => dispatch(userMountAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
